export const fetchDataAction = async (upc, dispatch) => {
  dispatch({ type: "FETCH_DATA" });
  try {
    // setTimeout(() => {
    // 	const upc = "0000000" + Math.floor(Math.random() * Math.floor(9));
    // 	dispatch({
    // 	  type: 'FETCH_DATA_COMPLETE',
    // 	  payload: {title: 'Test product', price: 10.99, image: '', upc }
    // 	});
    // 	dispatch({
    // 		type: 'ADD_ITEM',
    // 		payload: {title: 'Test product', price: 10.99, image: 'url', upc }
    // 	});
    // }, 1000);

    // Not sure why I need the headers, fd up.
    const response = await fetch(`/lookup_json.php?upc=${upc}`, {
      headers: { "x-requested-with": "XMLHttpRequest" },
    });
    const responseJSON = await response.json();
    if (
      !(
        Object.keys(responseJSON).length === 0 &&
        responseJSON.constructor === Object
      )
    ) {
      dispatch({
        type: "ADD_ITEM",
        payload: responseJSON,
      });
    }
    return dispatch({
      type: "FETCH_DATA_COMPLETE",
      payload: responseJSON,
    });
  } catch (e) {
    console.error(e);
    return dispatch({ type: "FETCH_DATA_ERROR" });
  }
};

export const fetchPublishDate = async (dispatch) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  dispatch({ type: "FETCH_PUBLISH_DATE" });
  try {
    const response = await fetch("/publish_time.php", requestOptions);
    const publishDate = (await response.json())["publish_date"];
    dispatch({ type: "FETCH_PUBLISH_DATE_SUCCEDED", publishDate });
  } catch (e) {
    dispatch({ type: "FETCH_PUBLISH_DATE_FAILED" });
    console.log("error getting the published date", e);
  }
};
