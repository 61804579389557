import React from 'react';
import { Row, Col, Image } from "react-bootstrap";
import missing from '../images/image-not-found.jpg';

const imageContainerStyle = {
	height: '200px', 
	width: '200px'
};

const imageStyle = {
	maxWidth: '100%',
	maxHeight: '100%'
};

const ProductImage = (props) => <Image 
	src={props.productImage ? props.productImage : missing} 
	style={imageStyle} 
	thumbnail />;

export default function ProductInfo(props) {
	return (
		<Row>
			<Col className={"justify-content-center"} style={imageContainerStyle} >
				<ProductImage productImage={props.image} /> 
			</Col>
			<Col className={"justify-content-center"}>
				<h5>{props.title}</h5>
				<h3>${parseFloat(props.price).toFixed(2)}</h3>
				<h5>UPC: {props.upc}</h5>
			</Col>
		</Row>
	);
}