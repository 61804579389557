import React, { useEffect } from "react";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { fetchPublishDate } from "./redux/Actions";
import { useStore } from "./redux/Store";

export default function Navigation() {
  const { state, dispatch } = useStore();

  useEffect(() => {
    fetchPublishDate(dispatch);
  }, []);

  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Navbar.Brand href="#home">Vitaglo.com</Navbar.Brand>
      {state.publishDate && <Navbar.Text>{state.publishDate}</Navbar.Text>}
      <Nav.Link
        className="ml-auto"
        href="http://pricecheck.vitaglo.com/missing_upc.php"
      >
        Missing UPCs
      </Nav.Link>
      <Nav.Link className="ml-auto" href="http://pricecheck-legacy.vitaglo.com">
        Use the old version
      </Nav.Link>
    </Navbar>
  );
}
