import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Spinner from "react-bootstrap/Spinner";
import { Row, Col } from "react-bootstrap";

import React, { useState, useEffect, useRef } from 'react';

const isValidUPC = (upc) => (upc.length >= 12) && !isNaN(upc);

export default function UPCInput(props) {
	const inputRef = useRef(null);

	const INVALID = "INVALID";
	const VALID = "VALID";
	const PENDING = "NONE";
	const [upcState, setUPCState] = useState(PENDING);

	useEffect(() => {
		if (props.isLoading === false && inputRef.current !== undefined) {
			inputRef.current.value = '';
			inputRef.current.focus();
		}
	}, [props.isLoading]);


	const handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			if (isValidUPC(event.target.value)) {
				setUPCState(VALID);
				props.handleValidUPC(event.target.value);
			} else {
				setUPCState(INVALID);
			}
		} else {
			setUPCState(PENDING)
		}
	}

	let inputClass = '';
	if (upcState === INVALID) {
		inputClass = 'is-invalid';
	} else if (upcState === VALID) {
		inputClass = 'is-valid';
	}

	return (
		<Row>
			<Col>
			</Col>
			<Col>
				<InputGroup>
					<InputGroup.Prepend>
						<InputGroup.Text id="basic-addon1">UPC</InputGroup.Text>
					</InputGroup.Prepend>
					<FormControl
					ref={inputRef}
					placeholder="Enter a UPC"
					aria-label="UPC"
					aria-describedby="basic-addon1"
					onKeyDown={handleKeyDown}
					className={inputClass}
					/>
				</InputGroup>
			</Col>
			<Col className={'text-left'}>
				<Spinner animation="border" className={!props.isLoading ? 'd-none': ''}/>
			</Col>
		</Row>
	);
}