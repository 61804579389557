import React from 'react'

export const Store = React.createContext();

const initialState = {
	upcData: undefined,
	error: false,
	isLoading: false,
  publishDate: undefined,
	currentOrder: []
}

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_DATA':
    	return { ...state, isLoading: true };
    case 'FETCH_DATA_COMPLETE':
    	return { ...state, isLoading: false, error: false, upcData: action.payload };
    case 'FETCH_DATA_ERROR':
    	return { ...state, isLoading: false, error: true };
    case 'REMOVE_ITEM':
    	return { ...state, currentOrder: state.currentOrder.filter(element => element.upc !== action.payload) };
    case 'ADD_ITEM':
    	return { ...state, currentOrder: [action.payload, ...state.currentOrder] };
    case 'CLEAR':
      return {...state, currentOrder: initialState.currentOrder }
    case 'FETCH_PUBLISH_DATE_SUCCEDED':
      return { ...state, publishDate: action.publishDate }
    default:
      return state;
  }
}

export function StoreProvider(props) {
	const [state, dispatch] = React.useReducer(reducer, initialState);
	const value = { state, dispatch };
	return (
    <Store.Provider value={value}>
      {props.children}
    </Store.Provider>
  );
}

export const useStore = () => React.useContext(Store);

