import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Container from "react-bootstrap/Container";
import ErrorBanner from "./components/ErrorBanner";
import Navigation from "./components/Navigation";
import PriceChecker from "./components/PriceCheck";
import React from "react";
import { useStore } from "./components/redux/Store";

function App() {
  const { state } = useStore();
  let errorBanner;
  if (state.error) {
    errorBanner = <ErrorBanner />;
  }

  return (
    <div className="App">
      <Navigation />
      {errorBanner}
      <div className={"mb-5"}></div>
      <Container>
        <PriceChecker />
      </Container>
    </div>
  );
}

export default App;
