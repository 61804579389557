import ProductInfo from './ProductInfo';
import React from 'react';
import Receipt from './receipt/Receipt';
import UPCInput from './UPCInput';
import { fetchDataAction } from './redux/Actions';
import { useStore } from './redux/Store';

export default function PriceChecker(props) {
	const { state, dispatch } = useStore();

	const handleValidUPC = (upc) => {
		fetchDataAction(upc, dispatch);
	};

	let productInfo;

	if (state.upcData !== undefined) {
	 	if (Object.keys(state.upcData).length === 0 && state.upcData.constructor === Object) {
			productInfo = <h5>Product not found.</h5>;
		} else {
			productInfo = (
		        <ProductInfo 
		        	title={state.upcData.title}
		        	price={state.upcData.price}
		        	upc={state.upcData.upc}
		        	image={state.upcData.image} />
			);
		}
	}

	return (
		<div>
	        <UPCInput isLoading={state.isLoading} handleValidUPC={handleValidUPC} />
	        <div className={'mb-5'}></div>
	        {productInfo}
	        <div className={'mb-5'}></div>
	       	<Receipt />
	       	<div className={'mb-5'}></div>
        </div>
	);
}