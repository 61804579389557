import Alert from "react-bootstrap/Alert";
import React from 'react';

export default function Error() {
	return (
		<Alert variant="danger">
			<Alert.Heading>Something is wrong!</Alert.Heading>
			<p>Unable to get results, try again later or contact IT.</p>
		</Alert>
	);
}