import { Button, Col, Image, ListGroup, Row } from "react-bootstrap";
import React, { useRef } from 'react';

import ReactToPrint from 'react-to-print';
import missing from '../../images/image-not-found.jpg';
import print from '../../images/print-24px.svg';
import { useStore } from '../redux/Store';

const imageStyle = {
	maxHeight: '100%', 
	maxWidth: '100%'
};

const imageContainerStyle = {
	height: '100px', 
	width: '100px'
};

function groupBy(list, props) {
  return list.reduce((a, b) => {
     (a[b[props]] = a[b[props]] || []).push(b);
     return a;
  }, {});
}

const ProductImage = (props) => <Image 
	src={props.productImage ? props.productImage : missing} 
	style={imageStyle} 
	thumbnail />;

function ReceiptItem(props) {
	const imageCol = !props.isForPrint ? (
		<Col xs={2} className={'mt-auto mb-auto'}>
			<div style={imageContainerStyle}>
				<ProductImage productImage={props.product.image} />
			</div>
		</Col>
	) : null;
	const removeItemCol = !props.isForPrint ? (
		<Col xs={1} className={'d-flex remove-item'}>
			<button type="button" className={"close"} aria-label="Close" onClick={(e) => {props.handleRemove(props.product.upc);}}>
				<span aria-hidden="true">&times;</span>
			</button>
		</Col>
	) : null;

	const price = parseFloat(props.product.price);
	const subTotal = parseFloat(price * props.count);
	return (
		<ListGroup.Item key={props.product.upc} className={"reciept_item"}>
			<Row>
				{removeItemCol}
				{imageCol}
				<Col xs={6} className={"text-left"}>
					<p>{props.product.title}</p>
					<p>UPC: {props.product.upc}</p>
				</Col>
				<Col className={"text-right"}>
					<p>${price.toFixed(2)}</p>
					<p>x{props.count}</p>
					<hr />
					<p>${subTotal.toFixed(2)}</p>
				</Col>
			</Row>
		</ListGroup.Item>
	);
}

const Total = (props) => {
	let total = 0;
	const groupedByUPC = groupBy(props.products, "upc");
	for (const groupedUPC of Object.keys(groupedByUPC)) {
		const count = groupedByUPC[groupedUPC].length;
		const product = groupedByUPC[groupedUPC][0];
		total += product.price * count;
	}
	return (<div id="total">{`Total: $${total.toFixed(2)}`}</div>);
}

// This needs to be a class to get the printing function to work.
const ReceiptList = (props) => {
	let productListByUPC = [];

	const groupedByUPC = groupBy(props.products, "upc");
	for (const groupedUPC of Object.keys(groupedByUPC)) {
		const count = groupedByUPC[groupedUPC].length;
		const product = groupedByUPC[groupedUPC][0];
		productListByUPC.push(
			<ReceiptItem 
				key={product.upc}
				product={product} 
				count={count}
				handleRemove={props.handleRemove}
				isForPrint={props.isForPrint} />
		);
	}

	return (
		<ListGroup>
			{productListByUPC}
		</ListGroup>
	);
}

function Toolbar(props) {
	return (
		<Row>
			<Col className={'text-left'} >
				<Button variant="primary" onClick={props.handleClear}>Clear</Button>
			</Col>
			<Col>
				<ReactToPrint 
					bodyClass={'printing'}
					trigger={() =>
						<Button variant="secondary">
							Print Receipt <Image src={print} />
						</Button>
					}
					content={() => props.printingRef.current}
				 />
			</Col>
			<Col className={'text-right'}>
				<Total products={props.products} />
			</Col>
		</Row>
	);
}

function Footer(props) {
	return (
		<Row>
			<Col className={'text-right'}>
				<Total products={props.products} />
			</Col>
		</Row>
	);
}

// Receipt has a top toolbar with actions, then the list of products.
// Also want the ability to print, with no images, and move the total to the bottom.
// Printing is done by hiding the DOM we wont want printed.
export default function Receipt() {
	const { state, dispatch } = useStore();
	const printingRef = useRef();

	const handleRemove = (upc) => {
		dispatch({type: 'REMOVE_ITEM', payload: upc});
	};

	const handleClear = () => {
		dispatch({type: 'CLEAR'})
	};

	if (state.currentOrder !== undefined && state.currentOrder.length > 0) {
		return (
			<div>
				<Toolbar 
					products={state.currentOrder}
					handleClear={handleClear} 
					printingRef={printingRef} />
				<div className={'mb-2'}></div>
			 	<ReceiptList
			 		products={state.currentOrder}
					handleRemove={handleRemove}
					handleClear={handleClear}
					isForPrint={false} />
				<div style={{display: 'none'}}>
					<div ref={printingRef} id={'receipt_print'}>
						<div className={'mt-5'}></div>
						<ReceiptList
							products={state.currentOrder}
							handleRemove={handleRemove}
							handleClear={handleClear}
							isForPrint={true} />
						<Footer products={state.currentOrder} />
					</div>
				</div>
			</div>
		);
	}
	return null;
}
